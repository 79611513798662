import Pagination from "react-js-pagination";
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';
import { strText } from '../../../../constants/english'
import { formatNumber } from '../../../../helpers/helpers'
import moment from 'moment'
import AddComment from "./addComment";
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_request_promoCode } from '../../../../helpers/request'
import { clearAll, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import Spinner from '../../../components/Spinner'
import { onUpdate, setAdminErrorMessageUser, setAdminLoadingUser } from '../../../../config/redux/slice/adminSlice'


export default function ClosedPayout() {

    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(new FormData());

    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [all_orders, setAll_orders] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [comment, setComment] = useState("");
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [boldRow, setBoldRow] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [actionType, setActionType] = useState('');
    const [selectedActions, setSelectedActions] = useState({});
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedSellers, setSelectedSellers] = useState({});
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrdersPerSeller, setSelectedOrdersPerSeller] = useState({});
    const [dropDownValue, setDropDownValue] = useState([]);
    const { userData, token } = useSelector((state) => state.authData)
    const [orderStatusId, setOrderStatusId] = useState(null);

    const dispatch = useDispatch()
    const {
        adminLoading,
        adminErrorMsg,
        adminSuccessMsg
      } = useSelector((state) => state.admin)

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        getPendingList();
    }, [])

    const {
        customerLoading,
    } = useSelector((state) => state.customer)

    const getPendingList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_Payout}/payouts?page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }



    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10; 

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_Payout}?payouts?page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handlePageChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handlePageChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handlePageChange ~ totalCount:...................", totalCount);

            setAll_orders(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(pageNumber);
    };

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();

        setNestedExpandedRows((prevState) => {
            const currentMainIndexState = prevState[mainIndex] || {};
            const isExpanded = currentMainIndexState[nestedIndex] || false;

            return {
                ...prevState,
                [mainIndex]: {
                    ...currentMainIndexState,
                    [nestedIndex]: !isExpanded, // Toggle the specific nested row
                },
            };
        });
    };

   
    const getSellerPayoutStatus = (orders) => {
        let status = 'NOT INITIATED';
        const statuses = orders.map(order => order.payoutStatus);

        if (statuses.every(status => status === 'PAID')) {
            return 'PAID';
        } else if (statuses.every(status => status === 'FAILED')) {
            return 'FAILED';
        } else if (statuses.some(status => status === 'PAID') && statuses.some(status => status === 'FAILED') ||
            statuses.some(status => status === 'PAID') && statuses.some(status => status === 'NOT INITIATED') ||
            statuses.some(status => status === 'FAILED') && statuses.some(status => status === 'NOT INITIATED')) {
            return 'MIXED';
        } else if (statuses.every(status => status === 'NOT INITIATED')) {
            return 'NOT INITIATED';
        }

        return status;
    };
    const getEventPayoutStatus = (eventOrders) => {
        let status = 'NOT INITIATED';

        const statuses = eventOrders.map(order => getSellerPayoutStatus(order.orders));

        if (statuses.every(status => status === 'PAID')) {
            return 'PAID';
        } else if (statuses.every(status => status === 'FAILED')) {
            return 'FAILED';
        } else if (statuses.some(status => status === 'PAID') && statuses.some(status => status === 'FAILED') ||
            statuses.some(status => status === 'PAID') && statuses.some(status => status === 'NOT INITIATED') ||
            statuses.some(status => status === 'FAILED') && statuses.some(status => status === 'NOT INITIATED')) {
            return 'MIXED';
        } else if (statuses.every(status => status === 'MIXED')) {
            return 'MIXED';
        }

        else if (statuses.every(status => status === 'NOT INITIATED')) {
            return 'NOT INITIATED';
        }

        return status;
    };
    
    return (

        <>
           <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Event Date</th>
                            <th scope="col" className='no-wrap'>Amount Due</th>
                            <th scope="col" className='no-wrap'>Payout Status</th>
                            <th scope="col" className='no-wrap'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : all_orders.length > 0 ? (
                            all_orders.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{item?.name}</td>
                                        <td>{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                        <td>{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</td>
                                        <td>{getEventPayoutStatus(item?.detailedPayouts || [])}</td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>

                                        </td>
                                    </tr>
                                    {(expandedRows[index] && item?.detailedPayouts?.length > 0 && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th className='no-wrap'>Seller Name</th>
                                                            <th className='no-wrap'>Seller Email</th>
                                                            <th className='no-wrap'>Seller Phone</th>
                                                            <th className='no-wrap'>Amount Due</th>
                                                            <th className='no-wrap'>Payout Status</th>
                                                            <th className='no-wrap'>Action</th>
                                                        </tr>
                                                    </thead>

                                                    {item?.detailedPayouts.map((items, sellerIndex) => {
                                                        const sellerPayoutStatus = getSellerPayoutStatus(items.orders); // Get the seller payout status

                                                        return (
                                                            <tbody key={sellerIndex}>
                                                                <tr key={index}>
                                                                   <td>{items.user.name}</td>
                                                                    <td>{items.user.email}</td>
                                                                    <td>{items.user.phone}</td>
                                                                    <td>{items?.dueAmount ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}` : 'N/A'}</td>
                                                                    <td>{sellerPayoutStatus}</td> {/* Displaying sellerPayoutStatus here */}
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="">
                                                                                <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, sellerIndex, event)}>
                                                                                    {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                                                        <i className="fa fa-angle-up fa-sizeset"></i>
                                                                                    ) : (
                                                                                        <i className="fa fa-angle-down fa-sizeset"></i>
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                {nestedExpandedRows[index] && (
                                                                    <tr>
                                                                        <td colSpan="7">
                                                                            <table className="table table-bordered tbl-subinner-width">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className='no-wrap'>Order Id/Sale Date</th>
                                                                                        <th className='no-wrap'>Number of Ticket</th>
                                                                                        <th className='no-wrap'>Ticket Price</th>
                                                                                        <th className='no-wrap'>Total Amount</th>
                                                                                        <th className='no-wrap'>PromoCode</th>
                                                                                        <th className='no-wrap'>Final Amount</th>
                                                                                        <th className='no-wrap'>Payout Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {items.orders.map((orderItem, itemIndex) => {
                                                                                        const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;
                                                                                        return (
                                                                                            <tr key={itemIndex}>
                                                                                                <td className={boldRow === dropdownKey ? 'fontWeight' : ""}>
                                                                                                    {orderItem?.orderRef}<br />
                                                                                                    {orderItem?.deliveryDate ? (format(new Date(orderItem?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}
                                                                                                </td>
                                                                                                <td>{orderItem?.orderItems.length || 0}</td>
                                                                                                <td>{orderItem?.orderItems[0].price ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.price ? `${strText.currency_symbol}${formatNumber(orderItem?.price)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.promoCodeDiscount ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.totalAmount ? `${strText.currency_symbol}${formatNumber(orderItem?.totalAmount)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.payoutStatus}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        );
                                                    })}


                                                </table>
                                            </td>
                                        </tr>
                                    ))}


                                </>

                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : all_orders.length > 0 ? (
                    all_orders.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.name}>{item?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Amount Due</h3>
                                        <div className="mob-tbl-value">{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Payout Status</h3>
                                        <div className="mob-tbl-value">{getEventPayoutStatus(item?.detailedPayouts || [])}</div>
                                    </div>


                                    <div className="mob-table-sec-expand">
                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                    </div>

                                </div>
                                {expandedRows[index] && item?.detailedPayouts?.length > 0 && (
                                    <>
                                        {item?.detailedPayouts.map((items, sellerIndex) => {
                                            const sellerPayoutStatus = getSellerPayoutStatus(items.orders); // Declare variable outside JSX

                                            return (
                                                <div className="mob-tbl-inner-sec" key={sellerIndex}>
                                                   <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Name</h3>
                                                        <div className={`mob-tbl-value`}>{items.user.name}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Email</h3>
                                                        <div className="mob-tbl-value">{items.user.email}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Phone</h3>
                                                        <div className="mob-tbl-value">{items.user.phone}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Amount Due</h3>
                                                        <div className="mob-tbl-value">
                                                            {items?.dueAmount
                                                                ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}`
                                                                : 'N/A'}
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Payout Status</h3>
                                                        <div className="mob-tbl-value">{sellerPayoutStatus}</div>
                                                    </div>

                                                    <div className="mob-table-sec-action">
                                                        <a
                                                            title="View Items"
                                                            className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd"
                                                            onClick={(event) => toggleNestedRow(index, sellerIndex, event)}
                                                        >
                                                            {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                            ) : (
                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                            )}
                                                        </a>
                                                    </div>

                                                    {nestedExpandedRows[index] && (
                                                        <>
                                                            {items.orders.map((orderItem, itemIndex) => {
                                                                const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;
                                                                return (
                                                                    <div className="mob-tb-subinner" key={itemIndex}>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Order Id/Sale Date</h3>
                                                                            <div
                                                                                className={`mob-tbl-value ${boldRow === dropdownKey ? 'fontWeight' : ''
                                                                                    }`}
                                                                            >
                                                                                {orderItem?.orderRef}
                                                                                <br />
                                                                                {orderItem?.deliveryDate
                                                                                    ? format(new Date(orderItem?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase()
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Number of Tickets</h3>
                                                                            <div className="mob-tbl-value">{orderItem?.numberOfTickets || 0}</div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Ticket Price</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.ticketPrice
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.ticketPrice)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Total Amount</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.orderItems[0].price
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">PromoCode</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.promoCodeDiscount
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Final Discount</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.totalAmount
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.totalAmount)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Payout Status</h3>
                                                                            <div className="mob-tbl-value">{orderItem?.payoutStatus}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                )}

                            </div>


                        </>

                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}

            </div>

            {showComplaintPopup && (
                <AddComment
                    setShowComplaintPopup={setShowComplaintPopup}
                    showComplaintPopup={showComplaintPopup}
                    selectedItem={selectedOrder}
                    actionType={actionType} // Pass the selected action type
                    setOrderStatusId={orderStatusId}
                    getPendingList={getPendingList}
                />
            )}
            {recordsTotal > 10?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}