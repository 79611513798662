import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import OpenAlertsList from './OpenAlertsList'
import MatchingAlertsList from './MatchingAlertsList'
import ClosedAlertsList from './ClosedAlertsList'
import ExpiredAlertsList from './ExpiredAlertsList'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TicketAlertSummary from './ticketAlertSummary'
import AdminWrapper from '../../../layout/AdminWrapper'


export default function AlertsTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listingId } = useNavigate()
  const [listingData, setListingData] = useState()
  const [listingCount, setListingCount] = useState()
  const {token} = useSelector((state) => state.authData)
  const {listingManagement: { newData, listingDetails, showListingModal: { show, type } }} = useSelector((state) => state.customer)
  
  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }
  return (
    <AdminWrapper heading={'Alerts'}>
     <TicketAlertSummary />
      <div className="payable-tblouter tbl-bg-white">
            <Tabs>
              <TabList>
                <Tab>Open Alerts</Tab>
                <Tab>Matching Alerts</Tab>
                <Tab>Closed Alerts</Tab>
                <Tab>Expired Alerts</Tab>
              </TabList>

              <TabPanel>
                <OpenAlertsList />

              </TabPanel>
              <TabPanel>
                <MatchingAlertsList/>
              </TabPanel>
            
              <TabPanel>
              <ClosedAlertsList/>

             

                </TabPanel>
                <TabPanel>
              <ExpiredAlertsList/>

              </TabPanel>
            </Tabs>
        </div>
    </AdminWrapper>
  )
}
