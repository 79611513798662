import {createSlice} from '@reduxjs/toolkit'
import { app_urls } from '../../../utils/urls/app_urls'
import thunk from 'redux-thunk';

const initialState = {
    appLoading: false,
    appErrorMsg: null,
    appSuccessMsg: null,
    breadCrumbName: [{id: 1, name:'User Management', url:app_urls.users_admin}],
    currentNavDetails: {},
    isActiveAdmin: 'admin',
    isActive: '',
    roles: [],
    cookieManagement: {
        acceptAllCookies: false,
        acceptMarketingCookies: false,
        acceptFunctionalCookies: false,
        acceptAnalyticsCookies: false,
        acceptEssentialCookies: false,
    },
    shouldModifyCart: false,
    eventManagement: {
        newData: false,
        
        showAddEventForm: false,
        eventDetails: {},
        eventData: [],
        searchResult: [],
        pageLoading: false,
        selectedMapSection: '',
        sectionData: [],
        showEventModal: {
            show: false,
            type: null,
            page: null
        },
        sectionColors: {},
    },
    cartManagement: {
        cartDetails: {},
        cartData: [],
        listingsInCart:[],
        ticketsInCart:[],
        cartNumber: 0,
        searchingTickets: false,
        maxPrice: '',
        showCartModal: {
            show: false,
            type: null 
        },
    }
}

export const appSlice = createSlice({
    name: 'appApp',
    initialState,
    reducers: {
        logoutAppUser: (state, action) => {
              return {
                ...initialState,
                eventManagement: {...initialState.eventManagement, 
                    pageLoading: state.eventManagement.pageLoading,
                    selectedMapSection: state.eventManagement.selectedMapSection,
                    eventDetails: state.eventManagement.eventDetails
                }
            }
        },
        clearAll: (state, action) => {
            state.appLoading = false
            state.appErrorMsg = null
            state.appSuccessMsg = null
        },
        clearOnReload: (state, action) =>{
            state.cartManagement = {
                ...initialState.cartManagement,
                cartData: state.cartManagement.cartData,
                cartNumber: state.cartManagement.cartNumber,
                listingsInCart: state.cartManagement.listingsInCart,
                ticketsInCart: state.cartManagement.ticketsInCart
            }
            state.eventManagement = {...initialState.eventManagement, 
                    eventDetails: state.eventManagement.eventDetails
                }
        },
        setAppLoading: (state, action) => {
            state.appLoading = action?.payload?.loading
        },

        setAppErrorMessage: (state, action) => {
            let msg = action.payload.message
            state.appLoading = false
            state.appErrorMsg = msg
        },
        setAppSuccessMessage: (state, action) => {
            let msg = action.payload.message
            state.appLoading = false
            state.appSuccessMsg = msg
        },
        setActiveSidebar : (state, action) => {
            state.isActive = action.payload.mainPath

        },
        setActiveAdminSidebar : (state, action) => {
            state.isAdminActive = action.payload.mainPath

        },
        setBreadCrumbName: (state, action) => {
            state.breadCrumbName = action.payload
        },
        setCurrentNavDetails: (state, action) => {
            state.currentNavDetails = action.payload
        },
        onGetRoles: (state, action) => {
            state.loading = false
            state.roles = action.payload
        },
        setDetails: (state, action) => {
            if(action.payload.type == 'event'){
                state.eventManagement.eventDetails = action.payload.details
            }
            
        },
        setSelectedMapSection: (state, action) =>{
            state.eventManagement.selectedMapSection = action.payload
        },
        setEventValues: (state, action) =>{
            state.eventManagement[action.payload.propertyKey] = action.payload.value
        },
        setCartValues: (state, action) =>{
            state.cartManagement[action.payload.propertyKey] = action.payload.value
        },
        setData: (state, action) => {
            if(action.payload.type == 'event'){
                state.eventManagement.eventData = action.payload.data
            }else if(action.payload.type == 'cart'){
                state.cartManagement.cartData = action.payload.data
            }
        },
        onAddToCart: (state, action) => {
            if(action.payload.sellTogether == 'neither'){
                state.cartManagement.listingsInCart = [
                    ...state.cartManagement.listingsInCart,
                    action.payload.ticketDetails.custom_id
                ]
            }
            state.cartManagement.cartData = [
                ...state.cartManagement.cartData,
                action.payload.ticketDetails 
            ]
        },
        // onGetCart: (state, action) => {
        //     let listingX = []
        //     let ticketX = []
        //     action.payload.forEach((listingOpt)=>{
        //         if(listingOpt.sellingOption == 'FULL'){
        //             listingX = [...listingX, listingOpt.id]
        //         }else if(listingOpt.sellingOption == 'AVOID_LEAVING_SINGLE'){

        //             let arr =  listingOpt.Tickets.map((option)=>{
        //                 return option.row
        //             })

        //             const uniqueSet = new Set(arr);

        //             // Convert the Set back to an array
        //             const uniqueArray = Array.from(uniqueSet);

        //             uniqueArray.forEach((option, ind)=>{
        //                 // let filteredTicket = listingOpt.Tickets.filter((opt)=>{
        //                 //     return opt.row == option
        //                 // })

        //                 listingX = [...listingX, `${listingOpt.id}custom${option}`]
        //             })
        //         }else{
        //             listingOpt.Tickets.forEach((opt)=>{
        //                 ticketX = [...ticketX, opt.id]
        //             })
        //         }
        //     })
        //     state.cartManagement.listingsInCart = listingX
        //     state.cartManagement.ticketsInCart = ticketX
        // },
        onGetCart: (state, action) => {
            
            let listingX = []
            let ticketX = []
            action.payload.forEach((listingOpt)=>{

                let newArray = []
                let arr =  listingOpt.Tickets.map((option)=>{
                    return option.row
                })

                const uniqueSet = new Set(arr);

                // Convert the Set back to an array
                const uniqueArray = Array.from(uniqueSet);

                newArray = listingOpt?.sellingOption === 'ANY' ? arr : uniqueArray

                newArray.forEach((option, ind)=>{
                    // let filteredTicket = listingOpt.Tickets.filter((opt)=>{
                    //     return opt.row == option
                    // })
                    let customId = listingOpt?.sellingOption === 'ANY' ? `${option}${ind}` : option

                    listingX = [...listingX, `${listingOpt.id}custom${customId}`]
                })
            })
            state.cartManagement.listingsInCart = listingX
        },
        // onRemoveFromCart: (state, action) => {
        //     let x = [];
        //     let selectedCartData = [];
        //     console.log(state.cartManagement.listingsInCart)
        //     let listingsInCart = [...state.cartManagement.listingsInCart]

        //     state.cartManagement.listingsInCart = listingsInCart.filter((opt)=>opt != action.payload.id)

        //     (state.cartManagement.cartData).forEach((opt)=>{
        //         if(opt.id != action.payload.listing.id){
        //             x.push(opt)
        //         }else if(opt.id == action.payload.listing.id){
        //             selectedCartData.push(opt)
        //         }
        //     })

        //         console.log(selectedCartData)
        //         selectedCartData.forEach((opt)=>{
        //             let y = opt.Tickets.filter((ticketOpt)=>action.payload.ticketIds.includes(ticketOpt.id) == false)
        //             if(y.length == 0){
        //                 x = [...x]
        //             }else{
        //                 x = [...x, {...opt, Tickets: y}]
        //             }
        //         })
        //         state.cartManagement.cartData = x

        //         console.log(state.cartManagement.listingsInCart)
        //         console.log(x)
        //         console.log(selectedCartData)
        //         console.log(state.cartManagement.cartData)

        // },
        onRemoveFromCart: (state, action) => {
            let x = [];
            let selectedCartData = [];
            console.log(state.cartManagement.listingsInCart)
            let listingsInCart = []

            state.cartManagement.listingsInCart.forEach((opt)=>{
                if(opt != action.payload.id){
                    listingsInCart.push(opt)
                }
            })

            console.log(listingsInCart);

            (state.cartManagement.cartData).forEach((opt)=>{
                if(opt.id != action.payload.listing.id){
                    x.push(opt)
                }else if(opt.id == action.payload.listing.id){
                    selectedCartData.push(opt)
                }
            });

                console.log(selectedCartData);
                selectedCartData.forEach((opt)=>{
                    let y = opt.Tickets.filter((ticketOpt)=>action.payload.ticketIds.includes(ticketOpt.id) == false)
                    if(y.length == 0){
                        x = [...x]
                    }else{
                        x = [...x, {...opt, Tickets: y}]
                    }
                });
                state.cartManagement.cartData = [...x];
                state.cartManagement.listingsInCart = [...listingsInCart];
                // state.cartManagement.listingsInCart = []

                console.log(state.cartManagement.listingsInCart);
                console.log(x);
                console.log(selectedCartData);
                console.log(state.cartManagement.cartData);

        },
        showCartModalFunc: (state, action) =>{
            state.cartManagement.showCartModal = {
                show: action.payload.modal,
                type: action.payload.type
            }
        },
        showEventModalFunc: (state, action) =>{
            state.eventManagement.showEventModal = {
                show: action.payload.modal,
                type: action.payload.type,
                page: action.payload.page
            }
        },
        setShouldModifyCart: (state, action) => {
            state.shouldModifyCart = action.payload
        },
        setCookiesValue: (state, action) => {
            state.cookieManagement[action.payload.propertyKey] = action.payload.value
        },
        setSectionColors: (state, action) => {
            state.eventManagement.sectionColors = action.payload;
          },
    },

})

// Action creators are generated for each case reducer function
export const {
    clearAll,
    setAppErrorMessage,
    setAppLoading,
    setAppSuccessMessage,
    logoutAppUser,
    setActiveSidebar,
    setActiveAdminSidebar,
    setBreadCrumbName,
    setCurrentNavDetails,
    onGetRoles,
    setCartValues,
    setSelectedMapSection,
    setDetails,
    setData,
    setEventValues,
    showCartModalFunc,
    onAddToCart,
    onGetCart,
    onRemoveFromCart,
    clearOnReload,
    setShouldModifyCart,
    showEventModalFunc,
    setCookiesValue,
    setSectionColors
} = appSlice.actions

export default appSlice.reducer