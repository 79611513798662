import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery' // Import jQuery
import 'maphilight'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'
import '../../../../assets/css/stadium_map_styles.css'
import {
  setEventValues,
  setSelectedMapSection,
  setSectionColors
} from '../../../../config/redux/slice/appSlice'

function StadiumMap({ stadiumObj }) {
  const dispatch = useDispatch()
  const imgRef = useRef()
  const canvasRef = useRef(null)
  const [areas, setAreas] = useState([])
  const [cardContainer, setCardContainer] = useState('')
  const [sectionColors, setLocalSectionColors] = useState({})
  const [subtraction] = useState(40)
  const timeoutId = useRef(null)

  const getColorFromMap = (area) => {
    if (!canvasRef.current || !imgRef.current) return null;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = imgRef.current.width;
    canvas.height = imgRef.current.height;

    ctx.drawImage(imgRef.current, 0, 0, canvas.width, canvas.height);

    const coords = area.getAttribute('coords').split(',');
    const x = parseInt(coords[0]);
    const y = parseInt(coords[1]);

    try {
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      return `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
    } catch (e) {
      console.error('Error getting color:', e);
      return null;
    }
  };

  useEffect(() => {
    if (cardContainer === '') {
      setCardContainer(document.getElementById('stadium-map-card'))
    }

    if (areas.length === 0) {
      setAreas(document.querySelectorAll('area'))
    }

    if (imgRef.current) {
      if (!canvasRef.current) {
        canvasRef.current = document.createElement('canvas');
      }

      imgRef.current.addEventListener('load', (event) => {
        setTimeout(() => {
          resizeImageMap()
          initializeMaphilight()

          // const colors = {};
          // areas.forEach(area => {
          //   const areaTitle = area.getAttribute('title');
          //   const color = getColorFromMap(area);
          //   if (color) {
          //     colors[areaTitle] = color;
          //   }
          // });

          // setLocalSectionColors(colors);
          // dispatch(setSectionColors(colors)); 
          // console.log('Section Colors:', colors);

          dispatch(setEventValues({ propertyKey: 'pageLoading', value: false }))
        }, [500])
      })
    }

  }, [imgRef, cardContainer, areas])

 

  useEffect(() => {
    if (areas) {
      areas.forEach((area) => {
        area.addEventListener('click', (event) => handleAreaClick(event, area));

        area.addEventListener('mouseover', (event) => {
          clearTimeout(timeoutId.current);

          timeoutId.current = setTimeout(() => {
            handleAreaClick(event, area);
          }, 1000);
        });

        area.addEventListener('mouseout', () => {
          clearTimeout(timeoutId.current);
        });
      });
    }

    return () => {
      areas.forEach((area) => {
        area.removeEventListener('click', (event) => handleAreaClick(event, area));
        area.removeEventListener('mouseover', (event) => {
          clearTimeout(timeoutId.current);
          setTimeout(() => {
            handleAreaClick(event, area);
          }, 1000);
        });
        area.removeEventListener('mouseout', () => {
          clearTimeout(timeoutId.current);
        });
      });
    };
  }, [areas]);

  useEffect(() => {
 return () => {
      $('.maparea').data('maphilight', false)
      if (imgRef.current) {
        imgRef.current.removeEventListener('load', (event) => {
          resizeImageMap()
        })
      }
      areas.forEach((area) => {
        area.removeEventListener('click', (event) =>
          handleAreaClick(event, area)
        )
      })
      imgRef.current = null
    }
  }, [])

  const initializeMaphilight = () => {
    $('.maparea').maphilight()
  }

  function resizeImageMap() {
    const newWidth = imgRef.current.clientWidth
    const newHeight = imgRef.current.clientHeight

    const scaleX = newWidth / stadiumObj.svgWidth
    const scaleY = newHeight / stadiumObj.svgHeight

    for (let i = 0; i < areas.length; i++) {
      const coords = areas[i].getAttribute('coords').split(',')

      const newCoords = coords.map((coord, index) => {
        return index % 2 === 0
          ? Math.round(coord * scaleX)
          : Math.round(coord * scaleY)
      })

      areas[i].setAttribute('coords', newCoords.join(','))
    }
  }

  const handleAreaClick = (event, area) => {
    event.preventDefault()
    const areaTitle = area.getAttribute('title')
    const color = sectionColors[areaTitle]
    
    console.log(`Clicked section: ${areaTitle}, Color: ${color}`);
    dispatch(setSelectedMapSection({
      section: areaTitle.toUpperCase(),
      color: color
    }))
  }

  return (
    <div style={{ backgroundColor: '#FFF' }} className="card border-0">
      <div id="stadium-map-card" className="card-body card-body-height border-0 px-2 px-sm-3">
        {cardContainer !== '' && cardContainer && (
          <>
            <img
              width={cardContainer.clientWidth - subtraction}
              id="stadium-svg"
              ref={imgRef}
              src={stadiumObj.svg}
              alt="stadium map"
              useMap="#image-map"
              className="maparea"
              crossOrigin="anonymous"
            />
          </>
        )}
        {parse(stadiumObj.hoverCode)}
       </div>
    </div>
  )
}

export default StadiumMap
