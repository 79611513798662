import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, clearResetBot, setCustomerErrorMessage, setDetails, showListingModalFunc, onGetProfileSales, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import { listingsCustomerDatatable } from '../../../../helpers/customer_dataTables'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import OpenAlertsList from './OpenAlertsList'
import MatchingAlertsList from './MatchingAlertsList'
import ClosedAlertsList from './ClosedAlertsList'
import ExpiredAlertsList from './ExpiredAlertsList'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TicketAlertSummary from './ticketAlertSummary'


export default function AlertsTableSeller() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listingId } = useNavigate()
  const [listingData, setListingData] = useState()
  const [listingCount, setListingCount] = useState()
  const {token} = useSelector((state) => state.authData)
  const {listingManagement: { newData, listingDetails, showListingModal: { show, type } }} = useSelector((state) => state.customer)
  
  // useEffect(() => {
  //   localStorage.setItem("activePage","")
  //   localStorage.setItem("searchTerm","")
  //     getList()
  //     }, [])

  // const handleUpdateListing = () => {
  //   var activePage = localStorage.getItem("activePage");
  //   var searchTerm = localStorage.getItem("searchTerm");
  //   // handlePageChange(activePage,searchTerm); 
  //   dispatch(showListingModalFunc({ modal: false, type: null })); 
  // };
//   const handlePageChange = async (event,searchTerm) => {
//     dispatch(clearAll({}));
//     const pageSize = 10; 
//     const res = await get_delete_request(
//         'get',
//         token,
//         `${app_api.list_ticket_alerts}?page=${event}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
//         {},
//         dispatch,
//         setCustomerLoading,
//         onGetProfileSales,
//         setCustomerErrorMessage,
//         true
//     );
//     console.log("🚀 ~ handleSearchChange ~ res:", res);
//     if (res !== undefined && res.status < 400) {
//       setListingData(res?.data?.data || [])
//       setListingCount(res?.data?.recordsTotal)
//     }
// }



  // const getList = async (page) => {
  //   dispatch(clearAll({}))
  //   const res = await get_delete_request(
  //     'get',
  //     token,
  //     `${app_api.list_ticket_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
  //     {},
  //     dispatch,
  //     setCustomerLoading,
  //     onGetProfileSales,
  //     setCustomerErrorMessage,
  //     false
  //   )
  //   if (res !== undefined && res.status < 400) {
  //     console.log(res?.data);
  //     setListingData(res?.data?.data || [])
  //     setListingCount(res?.data?.recordsTotal)
  //   }
  // }

  // const setItem = (data, type) => {
  //   dispatch(setDetails({ details: data, type: 'listing' }))
  //   if (type == 'details') {
  //     navigate(app_urls.user_listings_details)
  //   } else if (type == 'view-tickets') {
  //     navigate(app_urls.user_tickets_dyn(data.id))
  //   } else if (type == 'expand') {

  //   }
  //   else if (type == 'delete' || type == 'edit' || type == 'duplicate') {
  //     dispatch(showListingModalFunc({ modal: true, type }))
  //   }
  // }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }
  return (
    <CustomerWrapper heading={'Alerts'}>
     <TicketAlertSummary />
      <div className="payable-tblouter tbl-bg-white">
            <Tabs>
              <TabList>
                <Tab>Open Alerts</Tab>
                <Tab>Matching Alerts</Tab>
                <Tab>Closed Alerts</Tab>
                <Tab>Expired Alerts</Tab>
              </TabList>

              <TabPanel>
                <OpenAlertsList />

              </TabPanel>
              <TabPanel>
                <MatchingAlertsList/>
              </TabPanel>
            
              <TabPanel>
              <ClosedAlertsList/>

             

                </TabPanel>
                <TabPanel>
              <ExpiredAlertsList/>

              </TabPanel>
            </Tabs>
        </div>
    </CustomerWrapper>
  )
}
