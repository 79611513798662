import React, { useState } from 'react';
import AdminWrapper from '../../../layout/AdminWrapper';
import { useSelector } from 'react-redux';
import AllOrders from './all_orders';
import Complaints from './complaints';
import Payouts from './payouts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PayoutsAdmin from './payouts_admin';
import RefundRequest from './RefundRequest';
import OnHold from './OnHold';
import PayoutComplaint from './PayoutComplaint';
import OpenedPayout from './openedPayout';
import ClosedPayout from './closedPayout';
import OpenedRefund from './openedRefund';
import ClosedRefund from './closedRefund';

export default function PayoutsManagement() {

  const { userData, token } = useSelector((state) => state.authData)

  const defaultTab =
    userData.user_role === 'ADMIN'
      ? 'admin'
      : userData.user_role === 'FINANCE'
        ? 'finance'
        : 'customer_support';

  const {
    payoutManagement: { showPayoutModal: { show, type } }
  } = useSelector((state) => state.admin);

  const [activePrimaryTab, setActivePrimaryTab] = useState(defaultTab); // Default to customer_support

  return (
    <AdminWrapper>
      <div className="payable-tblouter order-tbl tbl-bg-white">
        {/* Buttons to switch between primary tabs */}
        <div className="primary-tab-buttons">
          {(userData.user_role === 'SUPER_ADMIN') && (

            <button
              className={`tab-button ${activePrimaryTab === 'customer_support' ? 'active' : ''}`}
              onClick={() => setActivePrimaryTab('customer_support')}
            >
              Customer Support
            </button>
          )}
          {/* Only SUPER_ADMIN can see the Admin tab button */}
          {userData.user_role === 'SUPER_ADMIN' && (
            <button
              className={`tab-button ${activePrimaryTab === 'admin' ? 'active' : ''}`}
              onClick={() => setActivePrimaryTab('admin')}
            >
              Admin
            </button>
          )}
          {(userData.user_role === 'SUPER_ADMIN') && (
            <button
              className={`tab-button ${activePrimaryTab === 'finance' ? 'active' : ''}`}
              onClick={() => setActivePrimaryTab('finance')}
            >
              Finance
            </button>
          )}
        </div>

        {/* Render content based on the activePrimaryTab */}
        {activePrimaryTab === 'customer_support' && (
          <Tabs>
            <TabList>
              <Tab>All Orders</Tab>
              <Tab>Payouts</Tab>
              <Tab>Complaints</Tab>
            </TabList>
            <TabPanel>
              <AllOrders />
            </TabPanel>
            <TabPanel>
              <Payouts />
            </TabPanel>
            <TabPanel>
              <Complaints />
            </TabPanel>
          </Tabs>
        )}

        {activePrimaryTab === 'admin' && (
          <Tabs>
            <TabList>
              <Tab>All Orders</Tab>
              <Tab>Payouts</Tab>
              <Tab>On Hold</Tab>
              <Tab>Refund Request</Tab>
              <Tab>Complaints</Tab>
            </TabList>
            <TabPanel>
              <AllOrders />
            </TabPanel>
            <TabPanel>
              <PayoutsAdmin />
            </TabPanel>
            <TabPanel>
              <OnHold />
            </TabPanel>
            <TabPanel>
              <RefundRequest />
            </TabPanel>
            <TabPanel>
              <PayoutComplaint />
            </TabPanel>
          </Tabs>
        )}

        {activePrimaryTab === 'finance' && (
          <Tabs>
            <TabList>
              <Tab>Opened Payout</Tab>
              <Tab>Closed Payout</Tab>
              <Tab>Opened Refund</Tab>
              <Tab>Closed Refund</Tab>
            </TabList>
            <TabPanel>
              <OpenedPayout />
            </TabPanel>
            <TabPanel>
              <ClosedPayout />
            </TabPanel>
            <TabPanel>
              <OpenedRefund />
            </TabPanel>
            <TabPanel>
              <ClosedRefund />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </AdminWrapper>
  );
}
